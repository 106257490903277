
<section class="mtb-30 mt-80 joblist-sec pd-btm-50 pd-tp50">
  <div class="container">

    </div>
    <!-- <lib-find-your-spot></lib-find-your-spot> -->
    <h3 class="title-1 txt-aln-cntr pd30-btm">
      <span class="themeBlack">Find Opportunities</span>	</h3>
    <div class="container text-center" *ngIf="!!fieldValue;else allJobsDisplayTemplateRef">
      <div class="row">
        <lib-jobs-list-es (jobURL)="setJobURL($event)" [fieldValue]="fieldValue" class="deparment-list"></lib-jobs-list-es>
      </div>
    </div>


    <ng-template #allJobsDisplayTemplateRef>
      <div class="container">
        <div class="row">
          <div class="col-md-3 mt-2">
            <lib-facets class="width-auto facet-lib"></lib-facets>
          </div>

          <div class="col-md-9">
            <div class="col-12">
              <lib-zm-search appearance="outline" label="Search" placeholder="Enter keywords to search"
                [showSearchIconInside]="true" (allJobsCount)="setJobsCount($event)" (onSearchEvent)="refreshJobsOnSearch($event)"></lib-zm-search>
            </div>

            <div class="col-12 mb-4">
              <div class="count-banner" *ngIf="allJobsCount >= 0">
                {{allJobsCount}} openings found
              </div>
              <lib-jobs-list (emitApplyData)="setTalentApplyResult($event)" [showMoreBtn]="true" [chipsViewForSkills]="true" (jobURL)="setJobURL($event)" class="job-list-sec"></lib-jobs-list>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-8 d-flex justify-content-end"><lib-pagination></lib-pagination></div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </ng-template>
    </section>
