import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  [x: string]: any;
  fieldValue: any = "";
  jobRoute: string = '';
  allJobsCount: any;
  jobsData: any = "";
  loaderhome: boolean = true;
  @ViewChild('target') target: any;


  @ViewChild('scrollDiv')
  scrollDiv!: ElementRef;


  @ViewChild('myButton')
  myButton!: ElementRef;
  @ViewChild('filtersPopup') filtersPopup: any;
  popUpRef: any;
  isMobileView: boolean = false;
  clearAll: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private appService: AppService, private el: ElementRef, private dialog: MatDialog) { 
    this.isMobileView = window.innerWidth < 992;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobileView = window.innerWidth < 992;
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      console.log(params);
      this.fieldValue = params.fieldValue;
      if(this.fieldValue!=''){
        this.loaderhome = false;
      }
    });

  }

  openPopup() {
    this.popUpRef = this.dialog.open(this.filtersPopup, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%'
    });
  }
  closePopup() {
    this.popUpRef.close('true');
  }
  clearSideFilter() {
    this.clearAll = true;
    setTimeout(() => {
      this.clearAll = false;
    }, 0);
  }

  refreshJobsOnSearch(e: any) {
    console.log("Search button hit");

  }

  showRecommendedJobs: boolean = false;
  onRecommendeJobsFetch(e: any) {
    this.showRecommendedJobs = true;
    this.jobsData = e;
    console.log("recommended jobs from app", e);

  }

  resetRecommendedJobsEventHandler(e: any) {
    this.showRecommendedJobs = !e;
  }

  setJobURL(jobURL: any): void {
    let url = window.location.href;
    url = url.substring(0, url.indexOf('jobslist/')) + 'jobview/' + jobURL;
    // let url = this.router.serializeUrl(this.router.createUrlTree(['jobview/' + jobURL]));
    window.open(url, '_blank');
  }
  setJobsCount(jobCount: any) {
    this.allJobsCount = jobCount;
  }
  isHidden: boolean = true;
  toggleVisibility(elementId: string) {
    this.isHidden = !this.isHidden;
    const element = document.getElementById(elementId);
    if (element) {
    // Scroll to the element
    element.scrollIntoView({ behavior: 'smooth' });
  }
  }
  
  refreshPage() {
    window.location.reload();
  }
  setTalentApplyResult(event: any) {
    if (event.code == 200) {
      this.appService.toast('success', event.message);
    }
    else {
      this.appService.toast('error', event.message);
    }
  }
  hideDivById() {
    // Get the div element by its ID
    const divElement = document.getElementById('myDiv');

    if (divElement) {
      // Set the display property to 'none' to hide the element
      divElement.style.display = 'none';
      this.showRecommendedJobs = !this.showRecommendedJobs;
      this.isHidden = true;
    }
  }
  @HostListener('window:scroll', ['$event'])

  onWindowScroll() {
      let element = document.querySelector('.navbar') as HTMLElement;
      if (window.pageYOffset > element.clientHeight) {
        element.classList.add('navbar-inverse');
      } else {
        element.classList.remove('navbar-inverse');
      }
    }
}
