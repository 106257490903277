<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid pd-90-lft-rt">
      <a class="navbar-brand" href="https://www.bizongo.com/">
        <img src="assets/Bizongo_logo.webp" data-src="assets/Bizongo_logo.webp" class="main-logo" alt="bizongo" height="58" width="68" loading="lazy">
      </a>
      <a class="mn-mnu-a nav-link ds-nn-dskp" aria-label="Home" title="Home" rel="noopener" href=".">
        <i class="fa fa-home"></i>
      </a>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="mn-mnu-a nav-link" title="Home"  aria-label="Home" rel="noopener" href=".">
                <i class="fa fa-home"></i>
              </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>