<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid pd-90-lft-rt">
      <a class="navbar-brand" href="https://www.bizongo.com/">
        <img src="assets/Bizongo_logo.webp" data-src="assets/Bizongo_logo.webp" class="main-logo" alt="bizongo" height="58" width="68" loading="lazy">
      </a>
      <a class="mn-mnu-a nav-link ds-nn-dskp" aria-label="Home" title="Home" rel="noopener" href=".">
        <i class="fa fa-home"></i>
      </a>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="mn-mnu-a nav-link" title="Home"  aria-label="Home" rel="noopener" href=".">
                <i class="fa fa-home"></i>
              </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
<section class="default-bg jb-list mrgn-tp-120">
		<div class="container-fluid">
			<div class="">
				<h1 class="default-bg-text ">
				</h1>
			</div>
		</div>
</section>

<!-- <section class="">
	<img src="assets/CareerpageIMG.webp" alt="banner" width="" height="">
</section> -->



<section class="mtb-30 mt-80 joblist-sec pd-btm-50">
	<div class="row">
		<div class="col-md-12 text-center">
			<h1 class="h1_heading pb-3">Current Openings</h1>
		</div>
	</div>
	  <!-- <lib-find-your-spot></lib-find-your-spot> -->
	  <div class="container text-center" *ngIf="!!fieldValue;else allJobsDisplayTemplateRef">
		<div class="row">
		  <lib-jobs-list-es (jobURL)="setJobURL($event)" [fieldValue]="fieldValue" class="deparment-list"></lib-jobs-list-es>
		</div>
	  </div>
  
	  <ng-template #allJobsDisplayTemplateRef>
		<div class="container kohler-joblist-space">
		  <div class="row">
			<!--<div class="col-md-3 mt-2"><lib-facets class="width-auto facet-lib"></lib-facets></div>-->
			<div *ngIf="!isMobileView" class="col-lg-3">
			  <lib-facets [isPopupRequired]=true [isHorizontal]=false themeName="zm-custom-theme" class="d-none d-lg-block"></lib-facets>
			</div>
			<div class="col-md-9">
			  <div class="col-12">
				<lib-zm-search appearance="outline" label="Search" placeholder="Enter keywords to search" [showSearchIconInside]="true" (allJobsCount)="setJobsCount($event)" (onSearchEvent)="refreshJobsOnSearch($event)"></lib-zm-search>
				<span class="filter-btn">
				  <button class="m-3" *ngIf="isMobileView" (click)="openPopup()">Filters</button>
				</span>
				<lib-filter-chips class="d-none-mob"></lib-filter-chips>
			  </div>
			  <div class="col-12 mb-4">
				<div class="count-banner" *ngIf="allJobsCount >= 0">
				  {{allJobsCount}} openings found
				</div>
				<lib-jobs-list (emitApplyData)="setTalentApplyResult($event)" [showMoreBtn]="true" [chipsViewForSkills]="true" (jobURL)="setJobURL($event)" class="job-list-sec"></lib-jobs-list>
			  </div>
			  <div class="col-12">
				<div class="row">
				  <div class="col-md-4"></div>
				  <div class="col-md-8 d-flex justify-content-end">
					<lib-pagination></lib-pagination>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </ng-template>
	  </section>
	  <ng-template #filtersPopup>
		<div class="d-flex justify-content-between mb-3 mt-2 mx-1">
		  <div>Filters</div>
		  <button class="btn-popup-close" (click)="closePopup()"><img src="assets/close-sm.svg" alt="close" height="24" width="24"></button>
		</div>
		<lib-filter-chips></lib-filter-chips>
		<lib-facets [isPopupRequired]=true [isUsedInPopup]=true [isHorizontal]=false [isClearAll]="clearAll" themeName="zm-custom-theme"></lib-facets>
		<div class="d-lg-none d-flex justify-content-end footer">
		  <button class="clear-sec" (click)="clearSideFilter()">CLEAR ALL</button>
		  <button mat-stroked-button class="ms-3 me-2 close-sec" (click)="closePopup()">SHOW JOBS</button>		</div>
	  </ng-template>

	
	<section class="section section-job-match" id="">
		<div class="container">
<lib-find-your-spot 
[descriptionText]="'Please provide your Resume or Curriculum Vitae and lets match you to current opportunities at bizongo'" *ngIf="!isHidden" themeName="zm-custom-theme" (recommendedJobsFetchEvent)="onRecommendeJobsFetch($event)"></lib-find-your-spot>
</div>
	 </section>

	<section class="job-section find-ur-div" id="myDiv" *ngIf="showRecommendedJobs">
        <div>
          <div class="container">
            <div class="row">
              <h3 class="reset-text text-center" *ngIf="jobsData.length > 0" >Matching opportunities recommended for you</h3>
            </div>
                <div class="row">
                  <div class="col-md-6 mb-50 col-lg-4"*ngFor="let attr of jobsData">
                    <div class="opening-block">
                      <h3><a href="javascript:void(0)"  (click)="setJobURL(attr.jobUrl)">{{attr.jobTitle}}</a></h3>
					  <p class="job-exp no-wrp"> Experience: <b>{{attr.minYrsOfExperience}} - {{attr.maxYrsOfExperience}} Years </b></p>
					  <p class="job-location no-wrp">Location <b> {{attr.location}}</b></p>
                      <div class="apply-row">
                        <!-- <a href="javascript:void(0)" (click)="setJobURL(attr.jobUrl)" class="btn-apply block-a"> Apply </a> -->
                      </div>
                    </div>
                  </div>
                    </div>
          </div>
             <div *ngIf="jobsData.length == 0 && showRecommendedJobs" id="msg">
        <div class="container">
          <div class="row ng-scope">
            <h3 class="reset-text">
				Sorry, there are no matching opportunities found. 
			</h3>
             <div class="col-md-12 text-center mt-20">
             </div>
           </div>
                   </div>
                  
       </div>
        </div>  
        <div class="row w-100 justify-content-center">
          <div class="col-md-12 text-center">
              <button  class="reset-div mt-20" (click)="hideDivById()">Reset</button>
          </div>
      </div>   
      </section>

	
  
