<header>
  <app-header></app-header>
</header>
<div [appLoading]="loaderDataLoad" [fixedLoader]="true"></div>
<section class="mtb-30 mt-80 jobView-sec">
  <div class="job-details-header">
    <div class="container">
        <div class="row dsply-flx-cntr pd-2-9">
            <div class="col-md-10">
               
                
                
                   <div class="row">
                    <div class="listing-header jobVwHeading mrgn-tp-btm">
                        <h2 class="defThmSubHeading align-titleJob ">{{jobTitle}}</h2>
                        <div> 
                    <h2 class="location"> Posted {{postedDate}}</h2>
                    <div class="line-left clearfix">|</div>
                    <div class="share-job-box defThmSubHeading customShareJobPadding">
                                                
                                    <div class="share-job-header align-header-jobShr ng-binding" id="heading1_jobview_jobheading_style2" ng-style="heading1_jobview_jobheading_style">
                                    <i class="fa fa-share-alt" aria-hidden="true"></i> Share Job&nbsp;</div>
                                      
                                      <div class="flt-lft">
                                                          
                                        <div class="social-share">
                                            <a href="" aria-label="facebook" rel="noopener" class="custom-thm8-outline" href="javascript:void(0)" (click)="genericSocialShare('fb')">
                                                <i class="fa fa-facebook"></i>
                                            </a>
                                            <a href="" class="custom-thm8-outline" aria-label="twitter" rel="noopener" href="javascript:void(0)" (click)="genericSocialShare('twitter')">
                                                <i class="fa fa-twitter"></i> </a> 
                                            <a href="" class="custom-thm8-outline" aria-label="linkedin" rel="noopener" href="javascript:void(0)" (click)="genericSocialShare('linkedin')">
                                                <i class="fa fa-linkedin"></i> </a> 
                                        </div>
                                </div>
                        </div>
                   </div>
                </div>
               
                            
                         
                                
            
                            </div>
            </div>    
        <div class="col-md-2 pd-lft-0 mrgn-tp-btm"><div class="job-apply-btn">
            <button mat-raised-button color="primary" (click)="onApplyClick()">Apply</button>
    </div>
    
    </div>
</div></div>
</div>
    <div class="container">
        <div class="job-details">
            <lib-job-view (jobViewLoad)="onJobViewLoad($event)"></lib-job-view>
        </div>
        <div class="row details-footer" #target>
            <div class="col-md-12 pd-2-9">
                <button mat-raised-button color="primary" (click)="onApplyClick()">Apply</button>
                <button mat-raised-button color="primary" (click)="onBackClick()">Back</button>
            </div>
        </div>
        <!-- <div *ngIf="loadApplyForm" class="apply-form">
            <lib-job-apply (applyResponse)="onJobSubmission($event)" (applyDataLoaded)="scrollToApply($event)"></lib-job-apply>
        </div> -->
        <div class="container">
            <div *ngIf="loadApplyForm" class="apply-form">
              <div class="row">
                <div class="col-md-8">
                    <lib-job-apply class="scrollable-div" (applyTrigger)="loaderLoad = true"  [isTncRequired]="false" (jobSuggestions)="getJobSuggestions($event)" (applyResponse)="onJobSubmission($event)" (applyDataLoaded)="scrollToApply($event)"></lib-job-apply>

                  <!-- <lib-job-apply class="scrollable-div" (applyTrigger)="loaderLoad = true" [isTncRequired]="true" (jobSuggestions)="getJobSuggestions($event)" (applyResponse)="onJobSubmission($event)" (applyDataLoaded)="scrollToApply($event)"></lib-job-apply> -->
                </div>
                <div *ngIf="jobSuggestions.length > 0" class="col-md-4 jb-rec-jbview pd-22rem">
                  <p class="job-rec-p text-center">
                    Based on your profile, you may want to check the below open positions before submitting
                </p>
                  <lib-job-recommendation themeName="zm-custom-theme" [jobs]="jobSuggestions" (jobURL)="setJobURL($event)"></lib-job-recommendation>
                </div>
              </div>
            </div>
          </div>
    </div>
</section>
<div [appLoading]="loaderLoad" class="loader-tranporent"></div>
