import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { JobconfirmComponent } from './jobconfirm/jobconfirm.component';
import { JoblistComponent } from './joblist/joblist.component';
import { JobviewComponent } from './jobview/jobview.component';

const routes: Routes = [
  { path: '',  pathMatch: 'full', component: HomeComponent },
  { path: 'jobslist', component: JoblistComponent },
  // { path: 'jobslist/:fieldValue', component: JoblistComponent },
  { path: 'joblistdept/:fieldValue', component: JoblistComponent },
  { path: 'jobview/', component: JobviewComponent },
  { path: 'jobview/:jobUrl', component: JobviewComponent },
  { path: 'jobview/campus/:jobUrl', component: JobviewComponent },
  { path: 'confirm', component: JobconfirmComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
