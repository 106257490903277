import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.scss']
})
export class JoblistComponent {
  fieldValue: any = "";
  jobRoute: string = '';
  allJobsCount: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private appService: AppService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      console.log(params);
      this.fieldValue = params.fieldValue;
    });
  }

  refreshJobsOnSearch(e: any) {
    console.log("Search button hit");

  }

  showRecommendedJobs: boolean = false;
  onRecommendeJobsFetch(e: any) {
    this.showRecommendedJobs = true;
    console.log("recommended jobs from app", e);

  }

  resetRecommendedJobsEventHandler(e: any) {
    this.showRecommendedJobs = !e;
  }

  setJobURL(jobURL: any): void {
    let url = window.location.href;
    url = url.substring(0, url.indexOf('jobslist/')) + 'jobview/' + jobURL;
    // let url = this.router.serializeUrl(this.router.createUrlTree(['jobview/' + jobURL]));
    window.open(url, '_blank');
  }

  setJobsCount(jobCount: any) {
    this.allJobsCount = jobCount;
  }

  setTalentApplyResult(event: any) {
    if (event.code == 200) {
      this.appService.toast('success', event.message);
    }
    else {
      this.appService.toast('error', event.message);
    }
  }

}

