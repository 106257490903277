import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { JoblistComponent } from './joblist/joblist.component';
import { ZmCareerSitesLibModule } from 'zm-careers-lib';
import { JobviewComponent } from './jobview/jobview.component';
import { JobconfirmComponent } from './jobconfirm/jobconfirm.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { LoadingDirective } from './directives/loading.directive';
import { JoblistdeptComponent } from './joblistdept/joblistdept.component';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    JoblistComponent,
    JobviewComponent,
    JobconfirmComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoadingDirective,
    JoblistdeptComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ZmCareerSitesLibModule,
    BrowserAnimationsModule
  ],
  exports: [
    LoadingDirective
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
